.app-logo {
  height: 50px;
  margin: 16px;
  text-align: center;
}

.site-layout .site-layout-background {
  background: #fff;
}

/*Sidebar*/
.responsive-button-sidebar-trigger {
  display: none !important
}

.menu-large-screen {
  display: inline-block;
}

.menu-small-screen {
  display: none;
}

@media (max-width: 576px) {
  .responsive-button-sidebar-trigger {
    display: inline-block !important;
    font-size: 18px;
    padding: 0 15px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .menu-large-screen {
    display: none;
  }

  .menu-small-screen {
    display: inline-block;
  }
}

/*Navbar Menu*/

.center-zone {
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.layout-footer {
  text-align: center !important;
  font-size: 13px !important;
  padding: 15px 50px !important;
}

#modal-create-new-product .ant-btn {
  margin-right: 8px;
}

.wrapper-class {
  border: 1px solid #ccc;
}

.upper {
  text-transform: uppercase;
}

.bold-font,
.bold {
  font-weight: bold;
}

.flex {
  display: flex;
}

.mg-0 {
  margin: 0px;
}

.mg-5 {
  margin: 5px;
}

.mg-10 {
  margin: 10px;
}

.mg-15 {
  margin: 15px;
}

.mg-20 {
  margin: 20px;
}

.mgr-0 {
  margin-right: 0px !important;
}

.mgr-5 {
  margin-right: 5px !important;
}

.mgr-10 {
  margin-right: 10px !important;
}

.mgr-15 {
  margin-right: 15px !important;
}

.mgr-20 {
  margin-right: 20px !important;
}

.mgr-30 {
  margin-right: 30px !important;
}

.mgl-0 {
  margin-left: 0px !important;
}

.mgl-5 {
  margin-left: 5px !important;
}

.mgl-10 {
  margin-left: 10px !important;
}

.mgl-15 {
  margin-left: 15px !important;
}

.mgl-20 {
  margin-left: 20px !important;
}

.mgl-30 {
  margin-left: 30px !important;
}

.mgt-0 {
  margin-top: 0px !important;
}

.mgt-5 {
  margin-top: 5px !important;
}

.mgt-10 {
  margin-top: 10px !important;
}

.mgt-15 {
  margin-top: 15px !important;
}

.mgt-20 {
  margin-top: 20px !important;
}

.mgt-30 {
  margin-top: 30px !important;
}

.mgt-50 {
  margin-top: 50px !important;
}

.mgb-0 {
  margin-bottom: 0px !important;
}

.mgb-5 {
  margin-bottom: 5px !important;
}

.mgb-10 {
  margin-bottom: 10px !important;
}

.mgb-15 {
  margin-bottom: 15px !important;
}

.mgb-20 {
  margin-bottom: 20px !important;
}

.mgb-30 {
  margin-bottom: 30px !important;
}

.padr-5 {
  padding-right: 5px !important;
}

.padr-10 {
  padding-right: 10px !important;
}

.padr-15 {
  padding-right: 15px !important;
}

.padr-20 {
  padding-right: 20px !important;
}

.padr-50 {
  padding-right: 50px !important;
}

.padl-5 {
  padding-left: 5px !important;
}

.padl-10 {
  padding-left: 10px !important;
}

.padl-15 {
  padding-left: 15px !important;
}

.padl-20 {
  padding-left: 20px !important;
}

.padl-30 {
  padding-left: 30px !important;
}

.padl-50 {
  padding-left: 50px !important;
}

.padt-5 {
  padding-top: 5px !important;
}

.padt-10 {
  padding-top: 10px !important;
}

.padt-15 {
  padding-top: 15px !important;
}

.padt-20 {
  padding-top: 20px !important;
}

.padt-50 {
  padding-top: 50px !important;
}

.padb-0 {
  padding-bottom: 0px !important;
}

.padb-5 {
  padding-bottom: 5px !important;
}

.padb-10 {
  padding-bottom: 10px !important;
}

.padb-15 {
  padding-bottom: 15px !important;
}

.padb-20 {
  padding-bottom: 20px !important;
}

.padb-50 {
  padding-bottom: 50px !important;
}

.pad-0 {
  padding: 0px;
}

.pad-5 {
  padding: 5px;
}

.pad-10 {
  padding: 10px;
}

.pad-15 {
  padding: 15px;
}

.pad-20 {
  padding: 20px;
}

.pad-30 {
  padding: 30px;
}

.light-bg {
  background-color: #f0f2f5;
}

.white-bg {
  background-color: white;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.italic {
  font-style: italic;
}

.colored {
  color: #13c2c2;
}

.green-color {
  color: #52c41a;
}

.red-color {
  color: #fa541c;
}

.orange-color {
  color: #f39c12;
}

.main-color {
  color: #1890ff;
}

.clickable {
  cursor: pointer;
  transition: all ease-in 200ms;
}

.clickable-main:hover {
  color: #1890ff;
}

.clickable-colored:hover {
  color: #13c2c2;
}

.clickable-red:hover {
  color: #fa541c;
}

.clickable-orange:hover {
  color: #f39c12;
}

.dark-grey-color {
  color: #555555;
}

.grey-color {
  color: rgba(0, 0, 0, 0.45);
}

.bordered {
  border: 1px solid #f0f0f0;
}

.font-70 {
  font-size: 70%;
}

.font-80 {
  font-size: 80%;
}

.font-90 {
  font-size: 90%;
}

.font-110 {
  font-size: 110%;
}

.font-120 {
  font-size: 120%;
}

.inline-block {
  display: inline-block;
}

.page-header-entry-layout {
  background-color: #f0f2f5;
}

.entry-layout {
  width: 100%;
}

.summary-row-background {
  background: #fafafa;
}

.text-small {
  font-size: 80%;
}

.rel {
  position: relative;
}

.abs {
  position: absolute;
}

.small-label {
  font-size: 70%;
  text-transform: uppercase;
  color: #666;
}

.medium-label {
  font-size: 80%;
  text-transform: uppercase;
  color: #666;
}

.inline-middle {
  display: inline-block;
  vertical-align: middle;
}

.hidden-row {
  opacity: 0.5;
}

.danger-switch.ant-switch-checked {
  background-color: #fa541c;
}