/* ANT CSS OVERRIDES */

.ant-form-item {
    margin-bottom: 10px;
}

@media screen and (max-width: 576px)
{
    .ant-layout-sider-collapsed {
        flex: 0 0 0px !important;
     max-width: 0px !important;
     min-width: 0px !important;
     width: 0px !important;
     }

     .ant-layout-sider-children {
         overflow:hidden
     }

     .ant-layout-sider-trigger {
         display:none
     }
     .menu-small-screen .ant-menu .ant-menu-submenu .ant-menu-submenu-title .ant-menu-item .anticon, .ant-menu-submenu-title .anticon{
     font-size: 18px;
     margin-right: 0;
    }
  /* .menu-small-screen .ant-menu .ant-menu-submenu {
    margin: 0 5px !important;
  } */
}